<template>
  <section class="conOfArticles h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage
            :headerPage="{ part1: 'International', part2: 'Articles' }"
          />
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col cols="12">
          <div class="conOfTabs mb-3">
            <v-btn
              class="activetab"
              :to="{ name: 'InternationalArticles' }"
              text
            >
              International
            </v-btn>
            <v-btn :to="{ name: 'nationalArticles' }" text> National </v-btn>
          </div>
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section
      class="mt-3 conOfSections"
      v-if="listItems && listItems.length > 0"
    >
      <v-container>
        <v-row>
          <v-col md="9" cols="12">
            <v-row>
              <v-col
                cols="12"
                v-for="(item, index) in listItems"
                :key="index"
                :md="item.is_full ? '8' : '4'"
                :sm="item.is_full ? '12' : '6'"
              >
                <template v-if="item.is_full">
                  <div class="conofcard">
                    <router-link
                      :to="{ name: 'articleItem', params: { id: item.id } }"
                    >
                      <v-card
                        class="mx-auto"
                        max-width="600"
                        height="275"
                        tile
                        elevation="0"
                      >
                        <v-container class="fill-height pa-0">
                          <v-row class="fill-height">
                            <v-col
                              md="6"
                              cols="12"
                              class="fill-height d-flex align-center"
                            >
                              <div class="conOfSec">
                                <v-card-title class="cardTitle">
                                  <div class="fillSecTitle">
                                    {{ item.title }}
                                  </div>
                                </v-card-title>
                                <v-card-text>
                                  <div class="fillSecDesc">
                                    {{ item.description }}
                                  </div>
                                </v-card-text>
                              </div>
                            </v-col>
                            <v-col md="6" cols="12" class="fill-height pa-0">
                              <div class="conOfCardImg fill-height">
                                <v-img
                                  :src="item.thumbnail"
                                  alt="item of the Image"
                                  class="fill-height"
                                ></v-img>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </router-link>
                  </div>
                </template>
                <template v-else>
                  <div class="conofcard">
                    <router-link
                      :to="{ name: 'articleItem', params: { id: item.id } }"
                    >
                      <v-card
                        class="mx-auto"
                        max-width="370"
                        height="275"
                        tile
                        elevation="0"
                      >
                        <div class="conOfCardImg">
                          <v-img
                            height="150"
                            :src="item.thumbnail"
                            alt="item of the Image"
                          ></v-img>
                        </div>
                        <div class="conOfSec">
                          <v-card-title class="cardTitle">
                            <div class="titleSec">
                              {{ item.title }}
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div class="cardDesc">
                              {{ item.description }}
                            </div>
                          </v-card-text>
                        </div>
                      </v-card>
                    </router-link>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="3" cols="12">
            <div
              class="conOfFeaturedSec"
              v-if="listFeaturedItems && listFeaturedItems.length > 0"
            >
              <div class="headerSec mb-5 mt-3">Featured Articles</div>
              <div
                class="conofcard"
                v-for="(item, index) in listFeaturedItems"
                :key="index"
              >
                <router-link
                  :to="{ name: 'articleItem', params: { id: item.id } }"
                >
                  <v-card
                    class="mx-auto"
                    max-width="370"
                    height="275"
                    tile
                    elevation="0"
                  >
                    <div class="conOfCardImg">
                      <v-img
                        height="150"
                        :src="item.thumbnail"
                        alt="item of the Image"
                      ></v-img>
                    </div>
                    <div class="conOfSec">
                      <v-card-title class="cardTitle">
                        <div class="titleSec">
                          {{ item.title }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="cardDesc">
                          {{ item.description }}
                        </div>
                      </v-card-text>
                    </div>
                  </v-card>
                </router-link>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Articles",
        disabled: false,
      },
    ],
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    listItems: null,
    listFeaturedItems: null,
    isLoading: false,
    isLoadingSearch: false,
    updatedCurrent_page: null,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "InternationalArticles",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    // getListData() {
    //   this.mediaCenter = [];
    //   this.isLoading = true;
    //   this.isLoadingSearch = true;

    //   apiServices
    //     .post("media?page=" + this.pagination.current_page, this.search)
    //     .then((res) => {
    //       if (res) {
    //         this.mediaCenter = res.data;
    //         this.pagination = res.meta;
    //         this.isLoading = false;
    //         this.isLoadingSearch = false;
    //       } else {
    //         this.isLoading = false;
    //         this.isLoadingSearch = false;
    //       }
    //     });
    // },

    getListData() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post(
          "articles/international?page=" + this.pagination.current_page,
          this.search
        )
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
    getListFraturesData() {
      this.listFeaturedItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices.get("articles/featured").then((res) => {
        if (res) {
          this.listFeaturedItems = res.data;
        }
      });
    },
  },
  created() {
    this.getListFraturesData();
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_Articles-International.scss";
</style>
